import request from '@/utils/request';

/**
 * @description 用户登录
 * @param {string}  username
 * @param {string}  password
 * @returns {string} token
 */

export function login(data) {
  return request.post('/sfxt/login', data);
}

export function loginCode(data) {
  return request.post('/sfxt/authCode?phone=' + data.phone);
}

/**
 * @description 注册
 * @returns {number} code
 */
export function registryAdd(data) {
  return request.post('/sfxt/registry/add', data);
}

/**
 * @description 获取验证码
 * @returns {number} code
 */
export function getPhoneCode(data) {
  return request.post('/sfxt/registry/authCode?phone=' + data.phone);
}

// /**
//  * @description 用户退出登录
//  * @returns {string}
//  */

// export function logout(data) {
//   return request.post('/user/logout', data);
// }

export function enterpriseInformation(data) {
  return request.post('/sfxt/registry/enterpriseInformation', data);
}

export function sfRegionList(data) {
  return request.post('/sfxt/sfRegion/list', data);
}

export function registryUpdateEnterpriseInformation(data) {
  return request.post('/sfxt/registry/updateEnterpriseInformation', data);
}

export function sfxtLogOut(data) {
  return request.post('/sfxt/logOut', data);
}

export function sysEmployeeExit(data) {
  return request.post('/sfxt/sysEmployee/exit', data);
}
