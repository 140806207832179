<template>
  <div class="backTop">
    <a-back-top :visibilityHeight="320">
      <svg-icon icon="backTop" />
    </a-back-top>
  </div>
</template>

<script>
export default {
  name: 'backTop',
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.backTop {
  ::v-deep .ant-back-top {
    background-color: #ff843f;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    bottom: 30px !important;
  }
}
</style>
