<template functional>
  <a-menu-item :key="props.currentRoute.path">
    <component
      :is="injections.components.appLink"
      :to="props.currentRoute.path"
      style="display: flex; align-items: center"
    >
      <a-icon
        v-if="props.currentRoute.meta.icon"
        theme="filled"
        style="font-size: 16px"
        :type="props.currentRoute.meta.icon"
      >
      </a-icon>
      <svg-icon class="svgmeta" v-else-if="props.currentRoute.meta.flag" :icon="props.currentRoute.meta.svg"></svg-icon>
      <svg-icon class="svgmetaFalse" v-else :icon="props.currentRoute.meta.svg"></svg-icon>
      <span style="margin-left: 5px; font-size: 13px" class="menu-title">{{ props.currentRoute.meta.title }}</span>
    </component>
  </a-menu-item>
</template>

<script>
import appLink from './link.vue';
export default {
  name: 'menuItem',
  components: { appLink },
  inject: {
    components: {
      default: {
        appLink
      }
    }
  },
  props: {
    currentRoute: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
::v-deep .svgClass.svgmeta {
  font-size: 23px !important;
  margin-right: 7px;
}
::v-deep .svgClass.svgmetaFalse {
  font-size: 20px !important;
  margin-right: 9px;
}
</style>
