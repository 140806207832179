<template functional>
  <a-sub-menu :key="props.currentRoute.path" v-if="!props.currentRoute.hidden">
    <template slot="title">
      <div style="display: flex; align-items: center">
        <a-icon
          :type="props.currentRoute.meta.icon"
          theme="filled"
          style="font-size: 16px"
          v-if="props.currentRoute.meta.icon"
        >
        </a-icon>
        <svg-icon
          class="svgmeta"
          v-else-if="props.currentRoute.meta.flag"
          :icon="props.currentRoute.meta.svg"
        ></svg-icon>
        <svg-icon class="svgmetaFalse" v-else :icon="props.currentRoute.meta.svg"></svg-icon>
        <span style="margin-left: 5px; font-size: 13px" class="menu-title">{{ props.currentRoute.meta.title }}</span>
      </div>
    </template>
    <template v-for="item in props.currentRoute.children">
      <menu-item v-if="!item.children && !item.hidden" :key="item.path" :currentRoute="item" />
      <sub-menu v-if="item.children && !item.hidden" :key="item.path" :currentRoute="item" />
    </template>
  </a-sub-menu>
</template>

<script>
import menuItem from './menuItem';
export default {
  name: 'subMenu',
  components: { menuItem },
  props: {
    currentRoute: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
::v-deep .svgClass.svgmeta {
  font-size: 23px !important;
  margin-right: 10px;
}
::v-deep .svgClass.svgmetaFalse {
  font-size: 20px !important;
  margin-right: 10px;
}
</style>
