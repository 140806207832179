<template>
  <a-dropdown class="navUser-wrppaer">
    <div style="display: flex; align-items: center">
      <span class="userTitle2">{{ accountInfo.enterpriseName }}</span>
      <span class="userTitle">{{ accountInfo.name }}</span>
      <a-icon type="caret-down" style="margin-left: 5px" />
    </div>

    <a-menu slot="overlay">
      <a-menu-item @click="goToPage('/userInfo/hide-userInfo')" v-check="'PD:enterprise:information'">
        <span>企业信息</span>
      </a-menu-item>
      <a-menu-divider />
      <a-menu-item @click.self="logout">
        <span>退出登录 </span>
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>

<script>
import check from '@/directive/checkPermission/check.js';
import { mapState } from 'vuex';
import { sfxtLogOut } from '@/api/user.js';
export default {
  name: 'navUser',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      accountInfo: state => state.user.accountInfo
    })
  },
  directives: {
    check
  },
  methods: {
    goToPage(url) {
      this.$router.push(url);
    },
    logout() {
      this.$store.commit('user/SET_LOADDING', true);
      sfxtLogOut({}).then(res => {
        if (res.code == 200) {
          // this.$message.success('退出登录');
          this.$store.dispatch('user/logout');
          this.$router.replace('/login');
          setTimeout(() => {
            this.$store.commit('user/SET_LOADDING', false);
          }, 1500);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.userImg {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  display: inline-block;
}
.userTitle {
  margin-left: 8px;
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
  width: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.userTitle2 {
  margin-left: 8px;
  font-weight: 700;
  font-size: 14px;
  display: inline-block;
}
</style>
