import store from '../../store';

// const storeObj = store;

const startLoading = (el, bind) => {
  let permission = store.getters.permissionList;
  if (!permission.includes(bind.value)) {
    el.style.display = 'none';
  }
};

export default {
  bind(el, bind) {
    startLoading(el, bind);
  },
  update(el, bind) {
    startLoading(el, bind);
  }
};
