import { getCache, setCache, removeCache } from '@/utils/session';
import { login } from '@/api/user';
import { resetRouter } from '@/router';
import store from '../index';

const state = {
  registerTime: '',
  sendTime: '',
  alreadyTime: ''
};

const mutations = {
  SET_TIME(state, registerTime) {
    state.registerTime = registerTime;
  },
  SET_SEND_TIME(state, sendTime) {
    state.sendTime = sendTime;
  },
  SET_ALREADY_TIME(state, alreadyTime) {
    state.alreadyTime = alreadyTime;
  }
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
