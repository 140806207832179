import { baseRoute, asyncRoutes } from '@/router';
import { authorityList } from '@/api/limit.js';
const state = {
  routes: [],
  menuList: [],
  permissionList: []
};

const mutations = {
  SET_ROUTE(state, route) {
    state.routes = route;
  },
  RESET_ROUTE(state) {
    state.routes = [];
  },
  SET_PERMISSION(state, permisssion) {
    state.permissionList = permisssion;
  },
  RESET_PERMISSION(state) {
    console.log('reset');
    state.routes = [];
  }
};

const actions = {
  getRoute({ commit }, role) {
    // return new Promise((resolve, reject) => {
    //   let accessedRoutes = [];
    //   accessedRoutes = filterAsyncRoute(asyncRoutes, role);
    //   commit('SET_ROUTE', accessedRoutes);
    //   resolve(accessedRoutes);
    // });

    return authorityList().then(res => {
      let accessedRoutes = [];
      let permission = res.data.list.map(item => {
        return item.authorityCode;
      });
      console.log(permission, 'pp');
      commit('SET_PERMISSION', permission);
      let routers = deepClone(asyncRoutes);
      accessedRoutes = getMenuListTree(routers, res.data.list);
      commit('SET_ROUTE', accessedRoutes);
      accessedRoutes.push(...baseRoute);
      return accessedRoutes;
    });
  }
  //   //仅测试用，生产请去掉
  //   async changeRole({ commit, dispatch }, role) {
  //     await dispatch('user/login', role);
  //     resetRouter();
  //     const accessedRoutes = await dispatch('getRoute', role);
  //     router.addRoutes(accessedRoutes);
  //     await dispatch('tagsView/clearTag', null, { root: true });
  //   }
};

function deepClone(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime());
  }

  if (obj instanceof Array) {
    return obj.reduce((arr, item, i) => {
      arr[i] = deepClone(item);
      return arr;
    }, []);
  }

  if (obj instanceof Object) {
    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] = deepClone(obj[key]);
      return newObj;
    }, {});
  }
}

export function getMenuListTree(routes, menuList) {
  let menuStrList = menuList.map(item => item.authorityCode);
  menuTree(menuStrList, routes);
  return routes;
}

function menuTree(list, routes) {
  if (routes) {
    for (let i = routes.length - 1; i >= 0; i--) {
      if (routes[i].meta && !routes[i].meta.base && !list.includes(routes[i].meta.role)) {
        routes.splice(i, 1);
      } else {
        menuTree(list, routes[i].children);
      }
    }
  } else {
    return;
  }
}

export function filterAsyncRoute(routes, role) {
  let arr = [];
  routes.forEach(item => {
    const temp = { ...item };
    if (hasChildren(temp, role)) {
      if (temp.children) {
        temp.children = filterAsyncRoute(temp.children, role);
      }
      arr.push(temp);
    }
  });
  return arr;
}

export function hasChildren(route, role) {
  let roleIds = role.split(',');
  if (route.meta && route.meta.role) {
    return roleIds.includes(String(route.meta.role));
  } else {
    return true;
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
