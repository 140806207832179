<template>
  <div id="app">
    <a-spin tip="数据加载中。。。" :spinning="loading" size="large">
      <a-config-provider :locale="locale">
        <router-view v-if="!loading" />
        <div v-else style="width: 100vw; height: 100vh"></div>
      </a-config-provider>
    </a-spin>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: 'App',
  watch: {
    '$store.getters.loading': {
      handler: function (newVal, oldVal) {
        console.log(`Message changed from ${oldVal} to ${newVal}`);
        this.loading = newVal;
      },
      immediate: true
    }
  },
  data() {
    return {
      locale: zhCN,
      loading: false
    };
  }
};
</script>
<style>
input {
  background-color: transparent;
}
#app {
  min-width: 1080px;
}
.font16 {
  font-size: 16px;
}
.font15 {
  font-size: 15px;
}
.flex {
  display: flex;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.bold {
  font-weight: bold;
}
.bolder {
  font-weight: bolder;
}
.justify-center {
  justify-content: center;
}
.align-items {
  align-items: center;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.flex-end {
  justify-content: flex-end;
}
.space-between {
  justify-content: space-between;
}
</style>
